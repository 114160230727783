
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css';

export function info(message, props) {
  return toast.info(message, {
    ...props,
  });
}

export function success(message, props) {
  return toast.success(message, {
    ...props,
  });
}
export function error(message, props) {
  return toast.error(message, {
    ...props,
  });
}

export function warning(message, props) {
  return toast.warning(message, {
    ...props,
  });
}
