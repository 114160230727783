import styled from "styled-components";

export const HeaderPanelView = styled.div`
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 10;
  max-width: 1250px;

  .control-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right 40px;
  }

  .searched_object {    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    border: 2px solid green;
    border-radius: 10px;
    padding: 15px 30px;
    background-color: white;
    color: green;
    min-width: 150px;
    text-align: center;
    padding: 10px 15px;
    color: rgb(72 99 39);
    
    span {
      font-size: 20px;
      letter-spacing: 1px;
      padding: 0 0 0 10px;
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
  }

  .score-wrapper {
    display: flex;
    justify-content: end;
  }

  .score {
    font-size: 20px;
    font-weight: bold;
    border: 1px solid rgb(87 125 42);
    border-radius: 10px;
    padding: 10px 15px;
    background-color: rgb(72 99 39);
    color: rgba(255, 255, 255, 0.8);
    min-width: 55px;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
`;
