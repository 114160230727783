import styled from "styled-components";

export const FailAlertView = styled.div`
  font-size: 25px;
  color: red;
  border: 2px solid red;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: white;
  font-weight: bold;
  display: flex;
`;
