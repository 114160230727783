import React from "react";
import { Stopwatch } from "../Stopwatch";
import { HeaderPanelView } from "./syles";

export const ControlPanel = ({
  currentItem,
  timerTime,
  timerOn,
  setTimerOn,
  setTimerTime,
  score,
}) => {
  return (
    <HeaderPanelView>
      <div className="control-panel">
        <Stopwatch
          timerTime={timerTime}
          timerOn={timerOn}
          setTimerOn={setTimerOn}
          setTimerTime={setTimerTime}
        />
        <div className="searched_object">
          Look for: <span>{currentItem.name}</span>
        </div>
        <div className="score-wrapper">
          <div className="score">Score: {score}</div>
        </div>
      </div>
    </HeaderPanelView>
  );
};
