import styled from "styled-components";

export const DetailsView = styled.div`
  background-color: #e8ffd1;
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;

  display: flex;
  flex-direction: column;

  color: #d2e1bf;
  box-shadow: 0 1px 0 rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 5%),
    0 5px 15px rgb(0 0 0 / 5%);

  background: rgb(72 99 39);
  position: relative;
  border-radius: 6px;

  &:hover:before {
    /* animation: none; */
    /* opacity: 0; */
  }
  &::before {
    content: "";
    width: 104%;
    height: 102%;
    border-radius: 8px;
    background-image: linear-gradient(
      var(--rotate),
      rgb(72 99 39),
      rgb(72 99 39) 43%,
      rgba(145, 197, 80, 1)
    );
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -2%;
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    0% {
      --rotate: 0deg;
    }
    100% {
      --rotate: 360deg;
    }
  }

  .header {
    text-align: center;
  }

  .form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .details-wrapper {
    width: 70%;
  }

  .submit-wrapper {
    margin-top: 20px;
    text-align: right;
  }

  .submit-btn {
    position: relative;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 50px;
    line-height: 50px;
    perspective: 230px;
    display: flex;
    padding: 0;
    background: none;
  }
  .submit-btn span {
    background: rgb(72 99 39);
    background: linear-gradient(0deg, rgb(44 61 24) 0%, rgb(72 99 39) 100%);
    display: block;
    position: absolute;
    width: 100%;
    height: 50px;
    box-shadow: inset 2px 2px 2px 0px rgba(72, 99, 39, 0.75),
      7px 7px 20px 0px rgba(72, 99, 39, 1), 4px 4px 5px 0px rgba(72, 99, 39, 1);
    border-radius: 5px;
    margin: 0;
    color: #d2e1bf;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    transition: all 0.3s;
  }
  .submit-btn span:nth-child(1) {
    box-shadow: -7px -7px 20px 0px rgba(72, 99, 39, 0.75),
      -4px -4px 5px 0px rgba(72, 99, 39, 0.85),
      7px 7px 20px 0px rgba(72, 99, 39, 0.95),
      4px 4px 5px 0px rgba(72, 99, 39, 1);
    transform: rotateX(90deg);
    transform-origin: 50% 50% -20px;
  }
  .submit-btn span:nth-child(2) {
    transform: rotateX(0deg);
    transform-origin: 50% 50% -20px;
  }
  .submit-btn:hover span:nth-child(1) {
    box-shadow: inset 2px 2px 2px 0px rgba(72, 99, 39, 0.75),
      7px 7px 20px 0px rgba(72, 99, 39, 1), 4px 4px 5px 0px rgba(72, 99, 39, 1);
    transform: rotateX(0deg);
  }
  .submit-btn:hover span:nth-child(2) {
    box-shadow: inset 2px 2px 2px 0px rgba(72, 99, 39, 0.75),
      7px 7px 20px 0px rgba(72, 99, 39, 1), 4px 4px 5px 0px rgba(72, 99, 39, 1);
    color: transparent;
    transform: rotateX(-90deg);
    z-index: -1;
  }
`;
