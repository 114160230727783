import React from "react";
import { Select as MuiSelect, MenuItem } from "@material-ui/core";
import { useField } from "formik";
import { SelectView } from "./styles";

export default function Select({
  label,
  options = [],
  multiple,
  tip,
  defaultValue = "",
  variant,
  color = "#255915",
  background,
  placeholder = "",
  value,
  ...other
}) {
  return (
    <>
      <SelectView background={background} color={color} variant={variant}>
        <label className="select-wrapper">
          {label && <div className="select-label">{label}</div>}
          <MuiSelect
            className="select"
            disableUnderline={true}
            displayEmpty={placeholder ? true : false}
            multiple={multiple}
            defaultValue={defaultValue}
            inputProps={{ "data-testid": "select" }}
            value={value}
            renderValue={
              value
                ? () => (
                    // console.log(value)
                    <div style={{ color: "rgba(255, 255, 255, 0.8)" }}>
                      {options.find(({ value: v }) => v === value).label}
                    </div>
                  )
                : () => (
                    <div style={{ color: "rgba(255, 255, 255, 0.27)" }}>
                      {placeholder}
                    </div>
                  )
            }
            {...other}
          >
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value} children={label} />
            ))}
          </MuiSelect>
        </label>
      </SelectView>
      {tip && (
        <div style={{ lineHeight: "11px", paddingBottom: 10 }}>{tip}</div>
      )}
    </>
  );
}

export function MappedSelect(props) {
  const errorStyle = {
    color: "#d8ffa8",
    fontSize: 11,
    letterSpacing: "1px",
    paddingLeft: 14,
  };

  function getTip(meta = {}, tip) {
    if (meta.touched && meta.error && typeof meta.error !== "object") {
      return <span style={errorStyle}>{meta.error}</span>;
    }
    if (tip) {
      return <span className="tip">{tip}</span>;
    }
  }

  const [field, meta] = useField(props.name);
  return (
    <div className="form-grp">
      <Select {...field} {...props} tip={getTip(meta, props.tip)} />
    </div>
  );
}
