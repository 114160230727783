import styled from "styled-components";

export const PlayButtonView = styled.div`
  text-align: center;
  padding: 20px 40px;
  border-radius: 16px;
  overflow: hidden;

  opacity: 1;
  transition: opacity 0.25s ease-in-out;

  background: #fff;
  background: hsla(0, 0%, 100%, 0.5);
  backdrop-filter: blur(10px) brightness(150%);

  .play-btn {
    outline: none;
    padding: 20px 40px;
    color: white;
    border: 1px solid green;
    border: 1px solid rgb(87 125 42);
    border-radius: 6px;
    font-size: 20px;
    cursor: pointer;
    background-color: rgb(72 99 39);
  }

  .description {
    color: rgb(72 99 39);
    font-size: 30px;
    margin-bottom: 30px;
  }
`;
