import React, { useEffect } from "react";
import { StopwatchView } from "./styles";

export const Stopwatch = ({ timerTime, timerOn, setTimerOn, setTimerTime }) => {
  useEffect(() => {
    let interval = null;
    if (timerOn) {
      if (timerTime === 0) {
        setTimerOn(false);
      }
      interval = setInterval(() => {
        setTimerTime((currentTime) => currentTime - 1000);
      }, 1000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerTime, timerOn]);

  const renderTime = () => {
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    return ` ${minutes} : ${seconds}`;
  };

  return (
    <StopwatchView>
      <div className="timer-wrapper">{renderTime()}</div>
    </StopwatchView>
  );
};
