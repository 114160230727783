import styled from "styled-components";

export const GabeboardView = styled.div`
  position: relatvie;
  overflow: hidden;

  .bg-image {
    z-index: -1;
    max-width: 1250px;
    max-height: 700px;
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
  }

  .svg_container {
    z-index: 100;
    max-width: 1250px;
    width: 100%;
    max-height: 700px;
    height: auto;
  }

  .svg_element {
    stroke-width: 1;
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #ffffff;
    stroke-opacity: 0;
    pointer-events: auto;
    transition: stroke 300ms, stroke-opacity 300ms, fill 300ms,
      fill-opacity 300ms;
    cursor: pointer;
    outline: none;
  }
`;
