import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { FailAlertView } from "./styles";

export const FailAlert = () => {
  return (
    <FailAlertView>
      <CloseIcon fontSize="large" />
      Wrong!
    </FailAlertView>
  );
};
