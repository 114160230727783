import React, { Fragment } from "react";
import { FinalScoreView } from "./styles";

export const FinalScore = ({
  guessedItems = [],
  timerTime = 0,
  handleClose,
  setLeaderboardOpen,
  resetGame,
}) => {
  const renderTime = () => {
    const usersTime = 120000 - timerTime;
    let seconds = ("0" + (Math.floor(usersTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(usersTime / 60000) % 60)).slice(-2);
    return ` ${minutes} : ${seconds}`;
  };

  const handleSubmit = () => {
    resetGame();
    handleClose();
    setLeaderboardOpen(true);
  };

  return (
    <FinalScoreView>
      <h3 className="score-header">Final Score</h3>
      <div className="scores-wrapper">
        <div className="time-score">
          Time : <span>{renderTime()}</span>
        </div>
        <div className="item-score">
          Score :{" "}
          <span>{guessedItems?.length ? guessedItems?.length : "0"}</span>
        </div>
      </div>
      <div className="score-table">
        <h4 className="score-header">Guessed Items</h4>
        {guessedItems.map(({ name, description }) => (
          <Fragment key={name}>
            <div className="score-table-row">
              <div className="score-table-lable">{`${name} :`}</div>
              <div className="score-table-value">{description}</div>
            </div>
            <hr />
          </Fragment>
        ))}
      </div>
      <div className="btn-wrapper">
        <button className="leaderboard-btn" onClick={handleSubmit}>
          View Leaderboard
        </button>
      </div>
    </FinalScoreView>
  );
};
