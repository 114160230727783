import React, { useState, useEffect } from "react";
import { getLeaderboard } from "../../api";
import { Loader } from "../../components/Loader";
import { useFetch } from "../../helpers/fetch.hook";
import { RankLine } from "./RankLine";
import { LeaderboardWrapper } from "./styles";
import Logo from "../../static/Logo.jpg";

export const LeaderBoard = ({ handleClose, setDetailsOpen }) => {
  const { request } = useFetch();

  const [topUsers, setTopUsers] = useState([]);
  const [bottomUsers, setBottomUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request(getLeaderboard)
      .then((data) => {
        if (!data) return;
        setTopUsers(data.top5);
        setBottomUsers(data.bottom5);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const handleRestart = () => {
    handleClose();
    setDetailsOpen(true);
  };

  return (
    <LeaderboardWrapper>
      {<Loader isOpen={loading} />}
      <div className="header">
        <div>
          <img src={Logo} alt="Logo" />
        </div>
        <div className="leaderboard">
          <strong>leaderboard</strong>
        </div>
      </div>
      <div className="final-standings">
        <strong> Final Standings</strong>
      </div>
      <div className="score-table">
        <h3 className="sub-header">
          {(topUsers || []).length ? "Top Players" : ""}
        </h3>
        <div className="table-header">
          <div className="rank-header">
            <strong>Rank</strong>
          </div>
          <div className="name-header">
            <strong>Name</strong>
          </div>
          <div className="score-header">
            <strong>Score</strong>
          </div>
        </div>
        {topUsers.map((item) => (
          <RankLine
            item={item}
            key={`${item.FirstName} + ${item.Score} +${item.TimeToComplete}`}
            className="top-users"
          />
        ))}
        <h3 className="sub-header" style={{ marginTop: 60 }}>
          {(bottomUsers || []).length ? "Bottom Players" : ""}
        </h3>
        <div className="table-header">
          <div className="rank-header">
            <strong>Rank</strong>
          </div>
          <div className="name-header">
            <strong>Name</strong>
          </div>
          <div className="score-header">
            <strong>Score</strong>
          </div>
        </div>
        {bottomUsers.map((item) => (
          <RankLine
            item={item}
            key={`${item.FirstName} + ${item.Score} +${item.TimeToComplete}`}
          />
        ))}
      </div>
      <div className="restart-wrapper">
        <button className="restart-btn" onClick={handleRestart}>
          Restart
        </button>
      </div>
    </LeaderboardWrapper>
  );
};
