import config from "react-global-configuration";

export const addUser = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/add-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
  });

export const getLeaderboard = () =>
  fetch(`${config.get("ApiUrl").Rest}/scoreboard`, {
    mode: "cors",
  });
