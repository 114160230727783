import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import { SuccessAlertView } from "./styles";

export const SuccessAlert = () => {
  return (
    <SuccessAlertView>
      <CheckIcon fontSize="large" />
      Correct!
    </SuccessAlertView>
  );
};
