import React, { forwardRef, useCallback } from "react";
import { useField } from "formik";
import { Input as MuiInput, Icon } from "@material-ui/core";
import { InputView } from "./styles";

const Input = forwardRef(function (
  {
    size = "large",
    label,
    icon,
    iconPosition = "start",
    tip,
    multiline,
    inputClassName,
    rows,
    background,
    fontColor = "rgba(255, 255, 255, 0.8)",
    color = "#255915",
    variant,
    inputWrapperStyle,
    ...other
  },
  ref
) {
  return (
    <>
      <InputView
        background={background}
        color={color}
        fontColor={fontColor}
        size={size}
        variant={variant}
        style={{ ...inputWrapperStyle }}
      >
        {label && <div className="input-label">{label}</div>}
        <div className="input-group">
          {icon && (
            <div className={`icon--${iconPosition}`}>
              <Icon>{icon}</Icon>
            </div>
          )}
          <MuiInput
            disableUnderline
            inputRef={ref}
            multiline={multiline}
            rows={rows}
            className="input"
            data-testid="input"
            {...other}
          />
        </div>
      </InputView>
      {tip && (
        <div style={{ lineHeight: "11px", paddingBottom: 10 }}>{tip}</div>
      )}
    </>
  );
});

export function MappedInput(props) {
  const errorStyle = {
    color: "#d8ffa8",
    fontSize: 11,
    letterSpacing: "1px",
    paddingLeft: 14,
  };

  function getTip(meta = {}, tip) {
    if (meta.touched && meta.error && typeof meta.error !== "object") {
      return <span style={errorStyle}>{meta.error}</span>;
    }
    if (tip) {
      return <span>{tip}</span>;
    }
  }

  const [field, meta] = useField(props.name);

  const getInputClasses = useCallback(() => {
    if (meta.touched && meta.error) {
      return "is-invalid";
    }

    if (meta.touched && !meta.error) {
      return "is-valid";
    }

    return "";
  }, [meta]);
  return (
    <Input
      {...field}
      {...props}
      inputClassName={getInputClasses()}
      tip={getTip(meta, props.tip)}
    />
  );
}

export default Input;
