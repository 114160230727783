export const itemsList = [
  {
    name: "Delivery",
    description:
      "Product release procedures are maintained for the final check of produce before dispatch. ",
  },
  {
    name: "Tractor",
    description: "Maintenance records are maintained for equipment and vehicles.",
  },
  {
    name: "Light Covers",
    description:
      "Lights above product handling and storage areas must be fitted with shatter proof covers or bulbs.",
  },
  {
    name: "Lock",
    description: "Chemical storage areas are kept secure.",
  },
  {
    name: "Nail",
    description: "Product handling areas are routinely inspected for foreign objects and material.",
  },
  {
    name: "Thermometer",
    description:
      "Cooling systems are checked to ensure they are operating at specified temperatures.",
  },
  {
    name: "Forklift",
    description: "Maintenance records are maintained for equipment and vehicles.",
  },
  {
    name: "Water Source",
    description: "Water sources are monitored and managed to minimise potential contamination.",
  },
  {
    name: "Growing Site",
    description: "Manage growing sites to minimise the risk of contaminating produce.",
  },
  {
    name: "Certificate",
    description:
      "Providing evidence of certification, a certificate is achieved upon successful completion of a Freshcare audit.",
  },
  {
    name: "Livestock",
    description:
      "Livestock movement records must be maintained. Livestock is not permitted on growing sites within 90 days of harvest for ground crops.",
  },
  {
    name: "Dog",
    description: "Domestic animals should be kept out of growing, packing, and storage areas.",
  },
  {
    name: "Waste Disposal",
    description:
      "Waste containers are provided, appropriate for use, clearly identified and emptied on a regular basis.",
  },
  {
    name: "Wash Station",
    description:
      "Hand washing facilities are easily accessed by team members before entry into the packing area.",
  },
  {
    name: "Safety Signs",
    description: "Ensure adequate signage is provided on-site to support team members.",
  },
  {
    name: "Barcode",
    description:
      "Maintain a product identification and traceability system to enable produce to be traced from production to its destination.",
  },
  {
    name: "Black Crates",
    description:
      "All packaging is checked for cleanliness, foreign objects and pest infestation before use.",
  },
];
