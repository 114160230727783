import React from "react";
import {
  LinearProgress,
  Dialog,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import { LoaderView } from "./styles";

const useStyles = makeStyles({
  bar: {
    color: "green",
    backgroundColor: "green",
  },
});

export const Loader = ({
  title = "... Loading",
  onClose,
  isOpen,
  maxWidth = "xs",
  ...other
}) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={maxWidth}
      {...other}
    >
      <DialogContent>
        <LoaderView>
          <div className="loading-wrapper">
            <svg
              width="200"
              height="38"
              viewBox="550 0 401 303"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="svg_container"
            >
              <path
                d="M391 95L376.793 183.136L266.396 226H191.967L47 118.318L50.8412 95.8987L391 95Z"
                fill="#2E3E8C"
              />
              <path
                d="M99.5 303C154.452 303 199 258.452 199 203.5C199 148.548 154.452 104 99.5 104C44.5477 104 0 148.548 0 203.5C0 258.452 44.5477 303 99.5 303Z"
                fill="#3D505A"
                className="svg_element"
                id="rear_tire"
              />
              <path
                d="M99.5 252C126.286 252 148 230.286 148 203.5C148 176.714 126.286 155 99.5 155C72.7142 155 51 176.714 51 203.5C51 230.286 72.7142 252 99.5 252Z"
                fill="#E7E8E2"
              />
              <path
                d="M335.5 302C371.675 302 401 272.675 401 236.5C401 200.325 371.675 171 335.5 171C299.325 171 270 200.325 270 236.5C270 272.675 299.325 302 335.5 302Z"
                fill="#3D505A"
                className="svg_element"
                id="front_tire"
              />
              <path
                d="M335.5 269C353.449 269 368 254.449 368 236.5C368 218.551 353.449 204 335.5 204C317.551 204 303 218.551 303 236.5C303 254.449 317.551 269 335.5 269Z"
                fill="#E7E8E2"
                stroke="#3D505A"
                stroke-width="2.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M310 95.0462V0H325V97L310 95.0462Z" fill="#3D505A" />
              <path
                d="M71.7695 9H131.305L138.713 9.0639L139 93.5048L54 94L71.7695 9Z"
                fill="#7DA7B3"
              />
              <path
                d="M131.1 10.393H136.378L136.666 91.1403L56.3261 91.607L73.2321 10.3447H131.1V10.393ZM131.1 6H69.7133L51 96L141 95.4529L140.68 6.08045H131.084L131.1 6Z"
                fill="#3D505A"
              />
              <path
                d="M135.387 9.07941L216.952 9L241 92.6506L135 93L135.387 9.07941Z"
                fill="#E7E8E2"
              />
              <path
                d="M214.714 10.3867L237.305 91.3219L137.339 91.6457L137.69 10.5H214.714V10.3867ZM217.905 6.11331H133.351L133 96L243 95.6439L217.969 6L217.905 6.11331Z"
                fill="#3D505A"
              />
              <rect
                x="199"
                y="112"
                width="166"
                height="48"
                fill="url(#pattern0)"
              />
            </svg>

            <div className="loader-title">{title}</div>
          </div>
          <LinearProgress classes={classes} />
        </LoaderView>
      </DialogContent>
    </Dialog>
  );
};
