import styled from "styled-components";

const smallInput = () => {
  return `
  width: calc(100% / 3 - 6px);
`;
};

const mediumInput = () => {
  return `
  width: calc(100% / 2 - 12px);
`;
};

const largeInput = () => {
  return `
  width: 100%;
`;
};

const inputSize = {
  small: smallInput(),
  medium: mediumInput(),
  large: largeInput(),
};

const nakedWrapper = () => {
  return `
  padding: 0;
  margin-bottom: 0;
  background: "transparent"
`;
};

const defaultWrapper = (background, color) => {
  return `
  background-color: ${background ? background : "rgba(0, 0, 0, 0.04)"};
  padding: 8px 15px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${color};
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
`;
};

export const InputView = styled.div`
  ${({ variant, background, color, fontColor }) =>
    variant === "naked" ? nakedWrapper() : defaultWrapper(background, color)}
  ${({ size }) => inputSize[size]}

  .input-group {
    display: flex;
  }

  .input {
    display: bock;
    width: 100%;
    color: ${({ fontColor }) => fontColor};
  }

  .MuiInput-input::placeholder {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  .input-label {
    font-size: 12px;
    color: ${({ color }) => color};
    letter-spacing: 0.4px;
  }

  .error {
    color: red;
    margin-bottom: 5px;
  }

  .icon---start {
    margin-left: 5px;
    margin-right: 5px;
    order: 1;
  }

  .icon--end {
    margin-left: 5px;
    margin-right: 5px;
    order: 2;
  }
`;
