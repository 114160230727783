import React from "react";

export const RankLine = ({ item = {}, className = "" }) => {
  const { FirstName, LastName, Score, Rank = 0 } = item;
  const name = [FirstName, LastName.charAt(0)].join(" ");
  return (
    <div className={`line-wrapper ${className}`}>
      <div className="player-rank">
        <strong>{Rank}</strong>
      </div>
      <div className="player-name">
        <strong>{name}.</strong>
      </div>
      <div className="player-score">
        <strong>{Score}</strong>
      </div>
    </div>
  );
};
