import styled from "styled-components";

export const LoaderView = styled.div`
  padding: 10px 5px;
  overflow: hidden;

  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .loader-title {
    margin-bottom: 10px;
    color: green;
  }
  .svg_container {
    animation: tractor_move 4s linear infinite;
  }
  #rear_tire {
    stroke-width: 1;
    pointer-events: auto;
    transition: stroke 300ms, stroke-opacity 300ms, fill 300ms,
      fill-opacity 300ms;
    animation: spin_rear_tire 2s linear infinite;
  }
  #front_tire {
    animation: spin_front_tire 2s linear infinite;
  }

  @keyframes tractor_move {
    0% {
      --rotate: 0deg;
      transform: translate(0, 0);
    }
    40% {
      transform: translate(10px, 1px) scale(1.001, 1.005);
    }
    50% {
      transform: translate(20px, 0px) rotate(-3deg);
    }
    100% {
      --rotate: 360deg;
      transform: translate(100%, 0);
    }
  }
  @keyframes spin_rear_tire {
    0% {
      --rotate: 0deg;
      transform: translate(0, 0) rotate(0);
    }
    40% {
      transform: translate(2px, 5px) scale(1.001, 1.005);
    }
    100% {
      --rotate: 360deg;
      transform: translate(0, 0) rotate(360deg);
    }
  }

  @keyframes spin_front_tire {
    0% {
      --rotate: 0deg;
      transform: translate(0, 0);
    }
    60% {
      transform: translate(2px, 5px) scale(1.001, 1.005);
    }
    100% {
      --rotate: 360deg;
      transform: translate(0, 0);
    }
  }
`;
