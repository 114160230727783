import styled from "styled-components";

const defaultWrapper = (background, color) => {
  return `
  background-color: ${background ? background : "rgba(0, 0, 0, 0.04)"};
  padding: 8px 15px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${color};
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
`;
};

const nakedWrapper = () => {
  return `
  background-color: none;
  padding: 8px 15px 0px;
  margin-bottom: 10px;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
`;
};

export const SelectView = styled.div`
  ${({ background, color, variant }) =>
    variant === "naked" ? nakedWrapper() : defaultWrapper(background, color)}

  &:focus-within {
    background: rgba(0, 0, 0, 0.05);
  }

  .select-wrapper {
    display: block;
    margin-bottom: 0;
  }

  .select-label {
    font-size: 12px;
    color: ${({ color }) => color};
    letter-spacing: 0.4px;
  }

  .select {
    border-color: #f3f6f9;
    color: #464e5f;
    transition: color 0.15s ease, background-color 0.15s ease,
      border-color 0.15s ease, box-shadow 0.15s ease;
    width: 100%;

    & .MuiSelect-select:focus {
      background-color: rgba(0, 0, 0, 0);
    }
    & .MuiSelect-icon {
      color: ${({ color }) => color} !important;
    }
  }

  .form-grp {
    margin-bottom: 1.75rem;
  }

  .error {
    color: "red";
  }

  .tip {
    color: "rgba(0, 0, 0, 0.6)";
  }
`;
