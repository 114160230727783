import * as Yup from "yup";
import { sortHelper } from "../../helpers";

export const initialValues = {
  FirstName: "",
  LastName: "",
  State: "",
  Email: "",
  Mobile: "",
  IndustryRole: "",
  Score: 0,
  TimeToComplete: 0,
};

export const roleOptions = [
  { value: "grower", label: "Grower" },
  { value: "supply_chain", label: "Supply Chain" },
  { value: "retail", label: "Retail" },
  { value: "service_provider", label: "Service Provider" },
  { value: "government", label: "Government" },
  { value: "industry_asoociation", label: "Industry Asoociation/Peak Body" },
  { value: "other", label: "Other" },
];

export const stateOptions = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "WA", label: "WA" },
  { value: "VIC", label: "VIC" },
].sort(sortHelper);

export const DetailsCaptureSchema = Yup.object({
  FirstName: Yup.string()
    .required("First Name is a required field")
    .matches(/(^[a-zA-Z\u00C0-\u024F -']+$)/, "Only alphabet characters are allowed"),
  LastName: Yup.string()
    .required("Last Name is a required field")
    .matches(/(^[a-zA-Z\u00C0-\u024F -']+$)/, "Only alphabet characters are allowed"),
  Mobile: Yup.string()
    .required("Phone Number is a required field")
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  Email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-zA-Z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  State: Yup.string().required("State is a required field"),
  IndustryRole: Yup.string().required("Role is a required field"),
});
