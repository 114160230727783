import styled from "styled-components";

export const LeaderboardWrapper = styled.div`
  background-color: #e8ffd1;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: rgb(72 99 39);
  /* color: rgba(255, 255, 255, 0.8); */

  .score-table {
    padding: 10px 40px;
  }

  .line-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .line-wrapper.top-users .player-rank {
    background-color: #cfa316;
    font-weight: 700;
    border: 1px solid #f0bf26;
    background-image: linear-gradient(
      var(--rotate),
      rgb(255 186 0),
      rgb(235 204 45) 43%,
      rgb(208 186 57)
    );
    /* animation: spin 4s linear infinite; */
  }

  .top-users:nth-child(3) .player-rank {
    background-color: #ffc91f;
    background-image: linear-gradient(
      var(--rotate),
      rgb(255 186 0),
      rgb(255 218 25) 43%,
      rgb(249 255 0)
    );
    animation: spin 4s linear infinite;
  }

  .top-users:nth-child(4) .player-rank {
    background-color: #e8b71b;
    background-image: linear-gradient(
      var(--rotate),
      rgb(255 186 0),
      rgb(255 214 0) 43%,
      rgb(247 214 17)
    );
    /* animation: spin 4s linear infinite; */
  }

  .player-rank,
  .player-score,
  .player-name {
    background-color: white;
    border-radius: 6px;
    border: 1px solid #accf8a;
    padding: 7px 0px;
    font-size: 20px;
    text-align: center;
    overflow: hidden;
  }

  .player-rank {
    min-width: 100px;
  }

  .player-score {
    min-width: 200px;
  }

  .player-name {
    margin: 0 30px;
    min-width: 200px;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 16px;
    text-transform: uppercase;
  }

  .rank-header {
    margin-left: 34px;
  }

  .name-header {
    margin-right: 42px;
  }

  .score-header {
    margin-right: 87px;
  }

  .header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: top;
    padding: 10px 20px 35px;
    margin-bottom: 20px;

    img {
      max-width: 200px;
      height: auto;
      @media (max-width: 768px) {
        max-width: 150px;
      }
    }
  }

  .leaderboard {
    font-size: 35px;
    text-transform: uppercase;
    align-self: center;
  }

  .final-standings {
    text-transform: uppercase;
    background-color: #accf8a;
    color: white;
    position: absolute;
    width: 100%;
    text-align: right;
    padding: 10px 40px;
    top: 86px;
    right: 0px;
    background: rgb(145, 197, 80);
    background: linear-gradient(
      14deg,
      rgb(72 99 39) 0%,
      rgba(145, 197, 80, 1) 39%,
      rgba(125, 167, 179, 1) 100%
    );
    letter-spacing: 1px;
  }

  .restart-wrapper {
    text-align: center;
  }

  .restart-btn {
    outline: none;
    padding: 12px 25px;
    color: rgb(72 99 39);
    border: 1px solid rgb(87 125 42);
    border-radius: 6px;
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  .sub-header {
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.8);
  }
`;
