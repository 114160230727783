import styled from "styled-components";

export const FinalScoreView = styled.div`
  background-color: #e8ffd1;
  background-color: rgb(72 99 39);
  color: rgba(255, 255, 255, 0.8);
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 16px;

  hr {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .score-header {
    text-align: center;
    font-size: 30px;
  }
  .scores-wrapper {
    display: flex;
    align-object: center;
    justify-content: space-around;
  }
  .time-score,
  .item-score {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    font-size: 18px;
    span {
      font-size: 24px;
      font-weight: 700;
      padding-left: 15px;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      color: rgb(72 99 39);
    }
  }

  .score-table-row {
    display: flex;
    justify-content: between;
    margin-bottom: 10px;
  }

  .score-table-lable {
    font-weight: bold;
    width: 20%;
    letter-spacing: 1px;
  }

  .score-table-value {
    margin-left: 20px;
    width: 80%;
  }

  .btn-wrapper {
    text-align: center;
    margin-top: 20px;
  }

  .leaderboard-btn {
    outline: none;
    padding: 12px 25px;
    color: rgb(72 99 39);
    border: 1px solid rgb(87 125 42);
    border-radius: 6px;
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
`;
