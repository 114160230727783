import React from "react";
import { PlayButtonView } from "./styles";

export const PlayButton = ({ handleClick }) => {
  return (
    <PlayButtonView>
      <h3 className="description">
        Find the Hidden Objects On Farm in the quickest possible time!
      </h3>
      <button className="play-btn" onClick={handleClick}>
        OK! Let's Play!
      </button>
    </PlayButtonView>
  );
};
