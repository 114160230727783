import styled from "styled-components";

export const StopwatchView = styled.div`
  /* font-size: 13px; */
  font-weight: bold;
  box-shadow: 0 1px 0 rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 5%),
    0 5px 15px rgb(0 0 0 / 5%);

  .timer-wrapper {
    border: 1px solid rgb(87 125 42);
    border-radius: 10px;
    padding: 10px 15px;
    background-color: rgb(72 99 39);
    color: rgba(255, 255, 255, 0.8);
    min-width: 40px;
    text-align: center;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
`;
