import { Formik } from "formik";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { MappedInput } from "../../components/Input";
import { MappedSelect } from "../../components/Select";
import {
  DetailsCaptureSchema,
  initialValues,
  roleOptions,
  stateOptions,
} from "./constants";
import { DetailsView } from "./styles";
import { useFetch } from "../../helpers/fetch.hook";
import { addUser } from "../../api";
import { success } from "../../helpers/toasts";
import { Loader } from "../../components/Loader";

const payload = {
  FirstName: "",
  LastName: "",
  State: "",
  Email: "",
  Mobile: "",
  IndustryRole: "",
  Score: 0,
  TimeToComplete: 0,
};

export const DetailsCapture = ({ handleClose, setPlayBtnVisible, setUser }) => {
  const { request } = useFetch();

  const storageUsers = JSON.parse(localStorage.getItem("users")) || {};

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    Object.keys(values).forEach((key) => {
      payload[key] = window.btoa(values[key]);
    });
    request(addUser, payload)
      .then((data) => {
        if (!data) return;
        setUser(values);
        localStorage.setItem(
          "users",
          JSON.stringify({ ...storageUsers, [values.Email]: values })
        );
        success("Success!");
        handleClose();
        setPlayBtnVisible(true);
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const findUser = (email = "", users = []) => {
    return users.find((user) => (user || {}).Email === email);
  };

  const handleEmailBlur = (values, setFieldValue, setTouched) => {
    const users = Object.values(storageUsers);
    const existingUser = findUser(values.Email, users);
    setTouched({ Email: true }, true);
    if (existingUser) {
      const fields = [
        "FirstName",
        "LastName",
        "Mobile",
        "State",
        "IndustryRole",
      ];
      fields.map((field) => setFieldValue(field, existingUser[field]));
    }
  };

  return (
    <DetailsView>
      <Loader isOpen={loading} />
      <ToastContainer />
      <h3 className="header">Please provide your details!</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={DetailsCaptureSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
          setTouched,
        }) => (
          <div className="form-wrapper">
            <div className="details-wrapper">
              <MappedInput
                name="Email"
                placeholder="Email"
                onBlur={() =>
                  handleEmailBlur(values, setFieldValue, setTouched)
                }
              />
              <MappedInput name="FirstName" placeholder="First Name" />
              <MappedInput name="LastName" placeholder="Last Name" />
              <MappedInput name="Mobile" placeholder="Mobile" />
              <MappedSelect
                name="State"
                placeholder="State"
                options={stateOptions}
              />
              <MappedSelect
                name="IndustryRole"
                placeholder="Role"
                options={roleOptions}
              />
              <div className="submit-wrapper">
                <button
                  className="submit-btn"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  <span>Let's go!</span>
                  <span>Play</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </DetailsView>
  );
};
