export const sortHelper = (a, b) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

export function shuffle(array = []) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
