import React from "react";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none"
  },
});

export function Modal({ onClose, isOpen, modalContent, ...other }) {
  const classes = useStyles();
  return (
    <Dialog onClose={onClose} open={isOpen} {...other} classes={classes}>
      <DialogContent>{modalContent} </DialogContent>
    </Dialog>
  );
}
