import { Router } from "./Router";
import config from "react-global-configuration";
import configuration from "./config";

config.set(configuration);

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
